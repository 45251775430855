import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then((x) => x.DashboardModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((x) => x.DashboardModule)
      },
      {
        path: 'instances',
        loadChildren: () => import('./instances/instances.module').then((x) => x.InstancesModule)
      },
      {
        path: 'bots',
        loadChildren: () => import('./bots/bots.module').then((x) => x.BotsModule)
      },
      {
        path: 'flows',
        loadChildren: () => import('./flows/flows.module').then((x) => x.FlowsModule)
      },
      {
        path: 'jobs',
        loadChildren: () => import('./jobs/jobs.module').then((x) => x.JobsModule)
      },
      {
        path: 'libraries',
        loadChildren: () => import('./libraries/libraries.module').then((x) => x.LibrariesModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((x) => x.SettingsModule)
      },
      {
        path: 'master',
        loadChildren: () => import('./master/master.module').then((x) => x.MasterModule)
      },
      {
        path: 'logs',
        loadChildren: () => import('./logs/logs.module').then((x) => x.LogsModule)
      },
      {
        path: 'configuration',
        loadChildren: () => import('./configuration/configuration.module').then((x) => x.ConfigurationModule)
      },
      {
        path: 'apikeys',
        loadChildren: () => import('./apikeys/apikeys.module').then((x) => x.ApikeysModule)
      },
      {
        path: 'credentialstore',
        loadChildren: () => import('./credentialstore/credentialstore.module').then((x) => x.CredentialstoreModule)
      },
      {
        path: 'queues',
        loadChildren: () => import('./queues/queue/queue.module').then((x) => x.QueueModule)
      },
      {
        path: 'itempriority',
        loadChildren: () => import('./queues/item-priority/item-priority.module').then((x) => x.ItemPriorityModule)
      },
      {
        path: 'customstatus',
        loadChildren: () => import('./queues/custom-status/custom-status.module').then((x) => x.CustomStatusModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notificattions/notificattions.module').then((x) => x.NotificattionsModule)
      },
      {
        path: 'tenants',
        loadChildren: () => import('./tenantlist/tenantlist.module').then((x) => x.TenantlistModule)
      },
      {
        path: 'apptokens',
        loadChildren: () => import('./apptokens/apptokens.module').then((x) => x.ApptokensModule)
      },
      {
        path: 'formbuilder',
        loadChildren: () => import('./formbuilder/formbuilder.module').then((x) => x.FormbuilderModule)
      },
      {
        path: 'taskcenter',
        loadChildren: () => import('./taskcenter/taskcenter.module').then((x) => x.TaskcenterModule)
      },
      {
        path: 'validationtask',
        loadChildren: () => import('./validationtask/validationtask.module').then((x) => x.ValidationtaskModule)
      },
      {
        path: 'customermodel',
        loadChildren: () => import('./customermodel/customermodel.module').then((x) => x.CustomermodelModule)
      },
      {
        path: 'ocr',
        loadChildren: () => import('./ocr/ocr.module').then((x) => x.OcrModule)
      },
      {
        path: 'createtable',
        loadChildren: () => import('./createtables/createtables.module').then((x) => x.CreatetablesModule)
      },
      {
        path: 'knowledgebase',
        loadChildren: () => import('./knowledgebase/knowledgebase.module').then((x) => x.KnowledgebaseModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }

